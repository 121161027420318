import React, { useState, useEffect } from "react";
import Footer from "./Common/Footer";
import Header from "./Common/Header";
import { click } from "@testing-library/user-event/dist/click";
function Contact() {


  function isValidEmail(email) {
    // Basic email validation regex
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const [Phonenumber, setPhonenumber] = useState();
  const [Email, setEmail] = useState();
  const [msg, setmsg] = useState();
  const [UserName, setUserName] = useState();

  const submitcontactUs = async () => {

    // Validate required fields
    if (UserName === '' || Phonenumber === '' || Email === '' || msg === '') {
      alert('Please fill out all required fields.');
      return;
    }
    // Validate email format (basic check)
    else if (!isValidEmail(Email)) {
      alert('Please enter a valid email address.');
      return;
    }
    else {
      const data = {
        ClientId: "16b79d96-52fa-4782-81f0-e24a7c822d25",
        Query: msg,
        Name: UserName,
        Email: Email,
        Mobile: Phonenumber,
        // Subject: msgsubject
      };
      const headers = {
        "Content-Type": "application/json",
        "clientid": "16b79d96-52fa-4782-81f0-e24a7c822d25",
        "page-name": "Contact_Us",
        //"SendToEmail": "Chandrakant111420@gmail.com",
        "EmailSubject": "Contact Form Submission",
      };
      try {
        const response = await fetch(
          "https://crmleads.erpthemes.com/savecrmlead",
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }
        console.log(response);
        const result = await response.json();
        if (result) {
          // alert(
          //   "Thank you for reaching out to us; we will get back to you shortly. Lead id: " +
          //   result.data[0].ID
          // );
          alert(result);
          window.scrollTo(0, 0);
          //window.location.reload();
          setPhonenumber('""');
          setEmail('');
          setmsg('');
          setUserName('');
        } else {
          console.log(result)
          alert("Please try again later.");
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    }
  };
  return (
    <>
      <div>
        <Header />
      </div>
      {/* Page Title */}
      <section
        className="page-title centred"
        style={{
          backgroundImage: "url(assets/images/background/page-title.jpg)"
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div
              className="shape"
              style={{
                backgroundImage: "url(assets/images/shape/shape-63.png)"
              }}
            />
            <div className="title">
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </section>
      {/* End Page Title */}
      {/* google-map-section */}
      <section className="google-map-section">
        <div className="map-inner">
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.4700782815644!2d77.08678737456724!3d28.49550149029197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19386d163f31%3A0x7607a237fcd132d6!2sTower%20A%2C%20DLF%20Tower%208th%20Rd%2C%20DLF%20Cyber%20City%2C%20DLF%20Phase%202%2C%20Sector%2024%2C%20Gurugram%2C%20Haryana%20122002!5e0!3m2!1sen!2sin!4v1720613065591!5m2!1sen!2sin"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="info-section">
          <div className="auto-container">
            <div className="info-inner clearfix">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-info-box">
                    <div className="icon-box">
                      <i className="icon-23" />
                    </div>
                    <h4>Corporate Office</h4>
                    <p>
                      2nd Floor, Tower-A Building No.9 DLF cyber city, Phase-III
                      Gurgaon-122002, Haryana
                    </p>
                  </div>
                  <div className="single-info-box">
                    <p>
                      <a href="tel:0124-4604500">0124-4604500</a>
                    </p>
                  </div>
                  <div className="single-info-box">
                    <p>
                      <a href="mailto:info@radheybiotech.com">
                        info@radheybiotech.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-info-box">
                    <div className="icon-box">
                      <i className="icon-23" />
                    </div>
                    <h4>R&D Center and Manufacturing Unit</h4>
                    <p>
                      Araji No. 612/613/617-623, Village-Mandap, Patwar,
                      Circle-Palanakhurd, Tehsil-Mavli District-Udaipur-313204,
                      Rajasthan
                    </p>
                  </div>
                  <div className="single-info-box">
                    <p>
                      <a href="tel:0294-3509100-199">0294-3509100-199</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* google-map-section end */}
      {/* contact-style-two */}
      <section className="contact-style-two sec-pad">
        <div className="auto-container">
          <div className="sec-title centred">
            <h2>Have Any Questions Contact With Us</h2>
          </div>
          <div className="form-inner">
            <div
              id="contact-form"
              className="default-form"

            >
              <div className="row clearfix">
                <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                  <input
                    type="text"
                    name="fullname"
                    placeholder="Name*"
                    required
                    value={UserName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email*"
                    required
                    value={Email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 form-group">
                  <input
                    name="phone"
                    placeholder="Phone*"
                    type="tel" minLength={10} maxLength={10}
                    value={Phonenumber}
                    required
                    onChange={(e) => {
                      setPhonenumber(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                    value={form.subject}
                    onChange={handleForm}
                  />
                </div>*/}
                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <textarea
                    name="message"
                    placeholder="Your Message"
                    value={msg}
                    onChange={(e) => {
                      setmsg(e.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                  <button
                    className="theme-btn-one"
                    type="submit"
                    name="submit-form"
                    onClick={submitcontactUs}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact-style-two end */}
      <div>
        <Footer />
      </div>
    </>
  );
}

export default Contact;
