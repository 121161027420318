import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';

function Footer() {
  return (
    <div>

          {/* main-footer */}
    <footer className="main-footer bg-color-1">
      <div className="footer-top">
        <div className="shape">
          {/* <div className="shape-1 rotate-me" style={{backgroundImage: 'url(assets/images/shape/shape-14.png)'}} /> */}
          <div className="shape-2 rotate-me" style={{backgroundImage: 'url(assets/images/shape/shape-14.png)'}} />
          <div className="shape-3" />
          <div className="shape-4" />
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget logo-widget">
                <div className="footer-logo">
                  <figure className="logo">     <img src="assets/images/logo.png" alt="" title className='logo' /></figure>
                </div>
                <div className="text">
                  <p>Established in 2023, Radhey Bio Tech Private Limited, one of India’s leading pharmaceutical companies has grown leaps and bounds to emerge as a well reputed organization for research and manufacturing of FGI & API

</p> 
             
                </div>
              </div>
            </div>
          
            <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget links-widget ml-70">
                <div className="widget-title">
                  <h4>Usefull Links</h4>
                </div>
                <div className="widget-content">
                  <ul className="links-list clearfix">
                  <li className="current"><Link to="/">Home</Link>
                
                </li>
                <li><Link to="/about">Company Overview</Link></li>
                  <li><Link to="/mission-vission">Mission, Vision and Core Values
                  </Link></li>
                  <li className=""><Link to="/gallery">Gallery</Link>
                   
                   </li> 
                <li className=""><Link to="/products">Products</Link>
                 
                </li> 
                <li className=""><Link to="/career">Career</Link>
                 
                </li> 
                <li className=""><Link to="/contact">Contact</Link>
                </li> 
                <li className=""><Link to="/terms">Terms & Conditions</Link>
                </li> 
                <li className=""><Link to="/privacy">Privacy Policy</Link>
                </li>
                 <li className=""><Link to="https://crmleads.erpthemes.com" target='_blank'>Login</Link>
                </li> 
               
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
              <div className="footer-widget subscribe-widget logo-widget">
                <div className="widget-title">
                  <h4>Connect with us</h4>
                </div>
                <div className="widget-content text">

                <ul className="info clearfix">
                    <li><i className="icon-26" />2nd Floor, Tower-A Building No.9 DLF cyber city, Phase-III Gurgaon-122002, Haryana</li>
                    <li><i className="icon-24" />Call Us: <a href="tel:0124-4604500">0124-4604500</a></li>
                    <li><i className="icon-25" /><a href="mailto:info@radheybiotech.com">info@radheybiotech.com</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom centred">
        <div className="auto-container">
          <div className="copyright">
            <p><a href="#">Radhey Biotech</a> © 2024 All Right Reserved</p>
          </div>
        </div>
      </div>
    </footer>
    {/* main-footer end */}
    {/*Scroll to top*/}
    {/* <button className="scroll-top scroll-to-target" data-target="html">
      <span className="fal fa-angle-up" />
    </button> */}
    </div>
  );
}

export default Footer;
